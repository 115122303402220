import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import SeoLegalPolicies from "../components/SeoLegalPolicies";
import CookieBanner from "../components/CookieBanner";

const LegalPoliciesPage = () => {
  return (
    <>
      <SeoLegalPolicies />
      <Header color="bg-primary text-black" />

      <main className="min-h-withHeaderFooter">
        <div className="bg-black text-white py-5 md:py-8 xl:py-8">
          <div className="container mx-auto">
            <h1 className="text-2xl md:text-4xl xl:text-5xl font-semibold mb-5">Terms &amp; Policies</h1>
            <p className="font-mono">
              The protection and confidentiality of your personal data is of particular importance for us.
            </p>
          </div>
        </div>

        <div className="container py-5 md:py-10 xl:py-10">
          <div className="grid grid-cols-1 lg:grod-cols-2 xl:grid-cols-4 gap-1 md:gap-5 xl:gap-5 mt-5">
            <Link to="/privacy-policy" target="_blank" className="card rounded-md">
              <StaticImage
                src="../images/privacy-policy.png"
                alt="Privacy policy"
                className="rounded-t-md w-full"
                quality={100}
              />
              <div className="m-5">
                <h5 className="font-semibold text-xl">Privacy Policy</h5>
                <p className="my-5">
                  Legal document that discloses some or all of the ways Slashscore gathers, uses, discloses, and manages
                  user's data.
                </p>
              </div>
            </Link>

            <Link to="/acceptable-use-policy" target="_blank" className="card rounded-md">
              <StaticImage
                src="../images/acceptable-policy.png"
                alt="Acceptable Use Policy"
                className="rounded-t-md w-full"
                quality={100}
              />
              <div className="m-5">
                <h5 className="font-semibold my-5 text-xl"> Acceptable Use Policy</h5>
                <p className="my-5">
                  This acceptable use policy sets out the terms between you and us under which you may access our web
                  application, Slashscore.
                </p>
              </div>
            </Link>

            <Link to="/terms-of-service" target="_blank" className="card rounded-md">
              <StaticImage
                src="../images/terms-of-service.png"
                alt="Terms of Service"
                className="rounded-t-md w-full"
                quality={100}
              />
              <div className="m-5">
                <h5 className="font-semibold my-5 text-xl"> Terms of Service</h5>
                <p className="my-5">Legal agreements between us and the user who wants to join and use Slashscore.</p>
              </div>
            </Link>

            <Link to="/cookies-policy" target="_blank" className="card rounded-md">
              <StaticImage
                src="../images/cookie-policy.png"
                alt="Cookie policy"
                className="rounded-t-md w-full"
                quality={100}
              />
              <div className="m-5">
                <h5 className="font-semibold my-5 text-xl"> Cookie policy</h5>
                <p className="my-5">
                  This policy provides information about how and when we use cookies for these purposes.
                </p>
              </div>
            </Link>
          </div>
        </div>
      </main>

      <Footer />
      <CookieBanner />
    </>
  );
};

export default LegalPoliciesPage;
